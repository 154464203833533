<template>
  <div class="flex flex-1 flex-col" style="min-height: 50vh">
    <table-container
      :theads="theads"
      :loading="loading"
      style="min-height: 50vh"
    >
      <div
        slot="tableHeader"
        class="flex flex-col justify-between space-y-2 md:w-full md:flex-row md:items-center md:space-y-0"
      >
        <form class="" @submit.prevent="fetch(null)">
          <div
            class="flex flex-col space-y-2 md:flex-row md:items-center md:space-x-3 md:space-y-0"
          >
            <div class="w-full md:w-56">
              <custom-select
                size="sm"
                label-size="sm"
                label-attr="name"
                value-attr="id"
                :options="statuses"
                :value="filters.status"
                @change="setStatus($event), fetch()"
              />
            </div>
            <div class="w-full md:w-56">
              <custom-select
                size="sm"
                label-size="sm"
                label-attr="name"
                value-attr="id"
                :options="types"
                :value="filters.type"
                @change="setType($event), fetch()"
              />
            </div>
            <div class="w-full md:w-56">
              <custom-select
                size="sm"
                label-size="sm"
                label-attr="shortName"
                value-attr="id"
                :options="priorityList"
                :value="filters.priority"
                @change="setPriority($event), fetch()"
              />
            </div>
            <div v-if="isAdmin && !displayUserTickets" class="w-48">
              <custom-select
                size="sm"
                label-size="sm"
                label-attr="name"
                value-attr="id"
                :searchable="true"
                height="50vh"
                :options="vets"
                :loading="!vets.length"
                :value="filters.vetId"
                @change="setVet($event), fetch()"
              />
            </div>
            <div v-if="isAdmin && !displayUserTickets" class="w-48">
              <custom-select
                size="sm"
                label-size="sm"
                label-attr="name"
                value-attr="id"
                :searchable="true"
                height="50vh"
                :options="filteredCountries"
                :loading="!countries.length"
                :value="filters.countryId"
                @change="setCountryId($event), fetch()"
              />
            </div>

            <base-button
              v-if="searched"
              color="cancel"
              type="button"
              class="inline-block"
              @click="clearFilters"
            >
              <div class="inline-flex items-center space-x-2">
                <fv-icon class="h-4 w-4" icon="close" />
                <span>Clear</span>
              </div>
            </base-button>
          </div>
          <div class="mt-4 w-full">
            <support-ticket-category-select
              :pre-selected-categories="preSelectedCategories"
              @update="setCategories($event), fetch()"
            />
          </div>
        </form>
        <base-button v-if="!searched" class="inline-block" @click="fetch">
          <div class="inline-flex items-center space-x-2">
            <fv-icon icon="switch" />
            <span>Refresh</span>
          </div>
        </base-button>
      </div>

      <SupportTicketRow
        v-for="ticket in tickets"
        :key="ticket.id"
        class="text-sm"
        :ticket="ticket"
      />

      <div v-if="loading" slot="tableLoading">
        <spinner-overlay color="transparent" size="xl" :loading="loading" />
      </div>
      <div
        slot="tableFooter"
        class="flex w-full items-baseline justify-between"
      >
        <div
          v-if="!tickets.length && !loading"
          class="text w-full py-8 text-center"
        >
          <span>No tickets match the selected filters</span>
        </div>
        <div
          v-if="tickets.length"
          class="flex w-full items-baseline justify-between"
        >
          <pagination-buttons
            v-if="ticketList.prev_page_url || ticketList.next_page_url"
            :pagination-list="ticketList"
            :loading="loading"
            @previous="fetch(ticketList.prev_page_url)"
            @next="fetch(ticketList.next_page_url)"
          />
          <div>
            Viewing {{ tickets.length }} of {{ ticketList.total }} entries
          </div>
        </div>
      </div>
    </table-container>
    <ticket-preview-modal
      :show="showTicketModal"
      :ticket="selectedTicket"
      @close="
        setShowTicketModal(false),
          setSelectedTicket({}),
          fetchIfCurrentTicketIsUpdated()
      "
      @updated="setTicketUpdatedFlag(true)"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import SupportTicketCategorySelect from '@/components/support/SupportTicketCategorySelect.vue';
import SupportTicketRow from '@/components/support/SupportTicketRow';
import PaginationButtons from '@/UI/PaginationButtons';
import TicketPreviewModal from '@/components/support/TicketPreviewModal';

export default {
  components: {
    PaginationButtons,
    SupportTicketRow,
    TicketPreviewModal,
    SupportTicketCategorySelect,
  },
  props: {
    displayUserTickets: {
      type: Boolean,
      default: false,
    },
    countryId: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      theads: [],
      selectedCategories: this.preSelectedCategories,
      categoryOptions: [],
      isCurrentTicketChanged: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapState('user', ['user']),
    ...mapGetters('support', {
      ticketList: 'getTicketList',
      showTicketModal: 'getShowTicketModal',
      selectedTicket: 'getSelectedTicket',
      preSelectedCategories: 'getPreSelectedCategories',
    }),
    ...mapGetters('country', { countries: 'getCountries' }),
    ...mapState('admin/schedule', ['vets']),
    ...mapState('support', ['filters', 'types', 'statuses', 'priorityList']),
    tickets() {
      if (this.ticketList?.data && this.ticketList?.data.length) {
        const ticketsCopy = this.ticketList.data.slice();
        return ticketsCopy.sort((a, b) => {
          const dateA = new Date(a.updated_at);
          const dateB = new Date(b.updated_at);
          return dateB - dateA;
        });
      }
      return [];
    },
    searched() {
      const countVetFilter = this.displayUserTickets
        ? false
        : !!this.filters.vetId;
      return !!(
        this.filters.status ||
        this.filters.type ||
        this.filters.priority ||
        countVetFilter
      );
    },
    filteredCountries() {
      return [
        {
          id: null,
          name: 'All countries',
        },
        ...this.countries,
      ];
    },
  },

  mounted() {
    if (this.displayUserTickets) {
      this.setVet(this.user.id);
      this.theads = [
        '',
        'ID',
        'Priority',
        'Message',
        'Type',
        'Status',
        'Latest update',
        '',
      ];
    } else {
      this.setVet(null);
      this.theads = [
        '',
        'ID',
        'User',
        'Priority',
        'Message',
        'Type',
        'Status',
        'Latest update',
        '',
      ];
    }

    if (this.countryId) {
      this.setCountryId(this.countryId);
    }

    if (!this.vets.length && !this.displayUserTickets) this.fetchVets();

    if (!this.countries.length && !this.displayUserTickets)
      this.fetchCountries();

    // Don't fetch again if coming back from support ticket view
    if (!this.$router.currentRoute.params.shouldNotReloadTicketList)
      this.fetch();
  },
  methods: {
    ...mapMutations('support', [
      'setPriority',
      'setType',
      'setStatus',
      'resetFilters',
      'setVet',
      'setCountryId',
      'setTicketList',
      'setSelectedTicket',
      'setShowTicketModal',
      'setCategories',
    ]),
    ...mapActions('support', ['fetchTickets']),
    ...mapActions('admin/schedule', ['fetchVets']),
    ...mapActions('admin', ['fetchCountries']),

    async fetch(pageUrl = null) {
      this.setTicketUpdatedFlag(false);
      this.loading = true;
      this.setTicketList([]);
      await this.fetchTickets(pageUrl)
        .then(res => {
          this.setTicketList(res.data);
        })
        .catch(e => {
          console.log({ e });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearFilters() {
      this.resetFilters();
      this.fetch();
    },
    setTicketUpdatedFlag(isChanged) {
      this.isCurrentTicketChanged = isChanged;
    },
    fetchIfCurrentTicketIsUpdated() {
      if (this.isCurrentTicketChanged) this.fetch();
    },
    openTicketModel() {
      this.setTicketUpdatedFlag(false);
      this.showTicketModal();
    },
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex flex-1 flex-col",
      staticStyle: { "min-height": "50vh" },
    },
    [
      _c(
        "table-container",
        {
          staticStyle: { "min-height": "50vh" },
          attrs: { theads: _vm.theads, loading: _vm.loading },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-col justify-between space-y-2 md:w-full md:flex-row md:items-center md:space-y-0",
              attrs: { slot: "tableHeader" },
              slot: "tableHeader",
            },
            [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.fetch(null)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-col space-y-2 md:flex-row md:items-center md:space-x-3 md:space-y-0",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "w-full md:w-56" },
                        [
                          _c("custom-select", {
                            attrs: {
                              size: "sm",
                              "label-size": "sm",
                              "label-attr": "name",
                              "value-attr": "id",
                              options: _vm.statuses,
                              value: _vm.filters.status,
                            },
                            on: {
                              change: function ($event) {
                                _vm.setStatus($event), _vm.fetch()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "w-full md:w-56" },
                        [
                          _c("custom-select", {
                            attrs: {
                              size: "sm",
                              "label-size": "sm",
                              "label-attr": "name",
                              "value-attr": "id",
                              options: _vm.types,
                              value: _vm.filters.type,
                            },
                            on: {
                              change: function ($event) {
                                _vm.setType($event), _vm.fetch()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "w-full md:w-56" },
                        [
                          _c("custom-select", {
                            attrs: {
                              size: "sm",
                              "label-size": "sm",
                              "label-attr": "shortName",
                              "value-attr": "id",
                              options: _vm.priorityList,
                              value: _vm.filters.priority,
                            },
                            on: {
                              change: function ($event) {
                                _vm.setPriority($event), _vm.fetch()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.isAdmin && !_vm.displayUserTickets
                        ? _c(
                            "div",
                            { staticClass: "w-48" },
                            [
                              _c("custom-select", {
                                attrs: {
                                  size: "sm",
                                  "label-size": "sm",
                                  "label-attr": "name",
                                  "value-attr": "id",
                                  searchable: true,
                                  height: "50vh",
                                  options: _vm.vets,
                                  loading: !_vm.vets.length,
                                  value: _vm.filters.vetId,
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.setVet($event), _vm.fetch()
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAdmin && !_vm.displayUserTickets
                        ? _c(
                            "div",
                            { staticClass: "w-48" },
                            [
                              _c("custom-select", {
                                attrs: {
                                  size: "sm",
                                  "label-size": "sm",
                                  "label-attr": "name",
                                  "value-attr": "id",
                                  searchable: true,
                                  height: "50vh",
                                  options: _vm.filteredCountries,
                                  loading: !_vm.countries.length,
                                  value: _vm.filters.countryId,
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.setCountryId($event), _vm.fetch()
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.searched
                        ? _c(
                            "base-button",
                            {
                              staticClass: "inline-block",
                              attrs: { color: "cancel", type: "button" },
                              on: { click: _vm.clearFilters },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "inline-flex items-center space-x-2",
                                },
                                [
                                  _c("fv-icon", {
                                    staticClass: "h-4 w-4",
                                    attrs: { icon: "close" },
                                  }),
                                  _c("span", [_vm._v("Clear")]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4 w-full" },
                    [
                      _c("support-ticket-category-select", {
                        attrs: {
                          "pre-selected-categories": _vm.preSelectedCategories,
                        },
                        on: {
                          update: function ($event) {
                            _vm.setCategories($event), _vm.fetch()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              !_vm.searched
                ? _c(
                    "base-button",
                    { staticClass: "inline-block", on: { click: _vm.fetch } },
                    [
                      _c(
                        "div",
                        { staticClass: "inline-flex items-center space-x-2" },
                        [
                          _c("fv-icon", { attrs: { icon: "switch" } }),
                          _c("span", [_vm._v("Refresh")]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._l(_vm.tickets, function (ticket) {
            return _c("SupportTicketRow", {
              key: ticket.id,
              staticClass: "text-sm",
              attrs: { ticket: ticket },
            })
          }),
          _vm.loading
            ? _c(
                "div",
                { attrs: { slot: "tableLoading" }, slot: "tableLoading" },
                [
                  _c("spinner-overlay", {
                    attrs: {
                      color: "transparent",
                      size: "xl",
                      loading: _vm.loading,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "flex w-full items-baseline justify-between",
              attrs: { slot: "tableFooter" },
              slot: "tableFooter",
            },
            [
              !_vm.tickets.length && !_vm.loading
                ? _c("div", { staticClass: "text w-full py-8 text-center" }, [
                    _c("span", [
                      _vm._v("No tickets match the selected filters"),
                    ]),
                  ])
                : _vm._e(),
              _vm.tickets.length
                ? _c(
                    "div",
                    {
                      staticClass: "flex w-full items-baseline justify-between",
                    },
                    [
                      _vm.ticketList.prev_page_url ||
                      _vm.ticketList.next_page_url
                        ? _c("pagination-buttons", {
                            attrs: {
                              "pagination-list": _vm.ticketList,
                              loading: _vm.loading,
                            },
                            on: {
                              previous: function ($event) {
                                return _vm.fetch(_vm.ticketList.prev_page_url)
                              },
                              next: function ($event) {
                                return _vm.fetch(_vm.ticketList.next_page_url)
                              },
                            },
                          })
                        : _vm._e(),
                      _c("div", [
                        _vm._v(
                          " Viewing " +
                            _vm._s(_vm.tickets.length) +
                            " of " +
                            _vm._s(_vm.ticketList.total) +
                            " entries "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        2
      ),
      _c("ticket-preview-modal", {
        attrs: { show: _vm.showTicketModal, ticket: _vm.selectedTicket },
        on: {
          close: function ($event) {
            _vm.setShowTicketModal(false),
              _vm.setSelectedTicket({}),
              _vm.fetchIfCurrentTicketIsUpdated()
          },
          updated: function ($event) {
            return _vm.setTicketUpdatedFlag(true)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <tr
    class="bg-opacity-75 hover:bg-beigeLighter"
    :class="{
      'bg-yellow-100 hover:bg-yellow-200': hasUnreadMessage || ticket.new,
      'bg-blue-100 hover:bg-blue-200': previousTicketId === ticket.id,
    }"
  >
    <table-data
      class="cursor-pointer"
      @click.native="setShowTicketModal(true), setSelectedTicket(ticket)"
    >
      <div class="flex items-center justify-end">
        <div class="flex text-xs font-semibold text-white">
          <div v-if="hasNewTicket" class="mr-2 w-10">
            <div
              class="w-10 rounded-lg bg-yellow-400 px-2 py-1"
              :class="{ invisible: !ticket.new, hidden: !hasNewTicket }"
            >
              New
            </div>
          </div>
          <div v-if="ticket.answers && ticket.answers.length" class="w-10">
            <div
              class="w-8 rounded-lg px-2 py-1 text-center"
              :class="{
                'bg-yellow-400': hasUnreadMessage,
                'bg-primary-dark': !hasUnreadMessage,
                invisible: !ticket.answers.length && !ticket.new,
                hidden: !ticket.answers.length && ticket.new,
              }"
            >
              {{
                ticket.answers && ticket.answers.length
                  ? ticket.answers.length
                  : ''
              }}
            </div>
          </div>
        </div>
        <img
          v-if="ticket.country"
          class="ml-3 h-5 w-6"
          :src="
            require('@/assets/svg/flags/' +
              ticket.country.localization.toLowerCase() +
              '.svg')
          "
        />
      </div>
    </table-data>

    <table-data
      class="cursor-pointer"
      @click.native="setShowTicketModal(true), setSelectedTicket(ticket)"
    >
      <p>#{{ ticket.id }}</p>
    </table-data>
    <table-data
      v-if="isAdmin"
      class="cursor-pointer"
      @click.native="setShowTicketModal(true), setSelectedTicket(ticket)"
    >
      <div class="flex flex-col space-y-1">
        <p>
          {{ ticket.user.display_name }}
        </p>
      </div>
    </table-data>
    <table-data
      class="cursor-pointer"
      @click.native="setShowTicketModal(true), setSelectedTicket(ticket)"
    >
      <div class="flex flex-col space-y-1">
        <div class="inline-flex space-x-2">
          <div v-if="ticket.priority">
            <fv-icon
              v-if="priorityIcon"
              :icon="priorityIcon"
              size="xs"
              :class="{
                'text-green-600': ticket.priority == 3,
                'text-yellow-600': ticket.priority == 2,
                'text-red-600': ticket.priority == 1,
                'animate-pulse': ticket.priority == 1 && !ticket.status,
              }"
            >
              {{ ticket.priority }}
            </fv-icon>
          </div>
          <p>
            {{
              priorityList.find(prio => prio.id == ticket.priority)
                ? priorityList.find(prio => prio.id == ticket.priority)
                    .shortName
                : '-'
            }}
          </p>
        </div>
      </div>
    </table-data>
    <table-data
      class="cursor-pointer"
      @click.native="setShowTicketModal(true), setSelectedTicket(ticket)"
    >
      <div class="flex flex-col space-y-1">
        <p class="max-w-xs truncate">
          {{ ticket.message }}
        </p>
      </div>
    </table-data>
    <table-data
      class="cursor-pointer"
      @click.native="setShowTicketModal(true), setSelectedTicket(ticket)"
    >
      <div class="flex flex-col space-y-1">
        <div class="inline-flex items-center space-x-2">
          <fv-icon v-if="typeIcon" class="text-gray-600" :icon="typeIcon" />
          <p>
            {{
              types.find(type => type.id === +ticket.type)
                ? types.find(type => type.id === +ticket.type).name
                : '-'
            }}
          </p>
        </div>
      </div>
    </table-data>
    <table-data
      class="cursor-pointer"
      @click.native="setShowTicketModal(true), setSelectedTicket(ticket)"
    >
      <div class="flex flex-col space-y-1">
        <div class="inline-flex items-center space-x-2">
          <fv-icon
            class="text-base"
            :class="ticket.status ? 'text-green-600' : 'text-black'"
            :icon="ticket.status ? 'check-mark' : 'warning'"
          />
        </div>
      </div>
    </table-data>
    <table-data
      class="cursor-pointer"
      @click.native="setShowTicketModal(true), setSelectedTicket(ticket)"
    >
      <p>
        {{ format(new Date(ticket.updated_at), 'HH:mm yyyy-MM-dd') }}
      </p>
    </table-data>
    <table-data>
      <div class="flex justify-end space-x-2">
        <button
          class="rounded border px-4 hover:opacity-75"
          @click="
            $router.push({
              name:
                $route.name === 'support-tickets'
                  ? 'support-ticket'
                  : 'message',
              params: {
                id: ticket.id,
              },
            })
          "
        >
          <fv-icon icon="chevron-right" size="md" />
        </button>
      </div>
    </table-data>
  </tr>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { format } from 'date-fns';

export default {
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      format,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapState('support', [
      'types',
      'statuses',
      'priorityList',
      'selectedTicket',
      'ticketList',
      'previousTicketId',
    ]),
    ...mapGetters('user', { userId: 'getUserId' }),
    priorityIcon() {
      const icons = {
        1: 'arrow-up',
        2: 'arrow-right',
        3: 'arrow-down',
      };
      return this.ticket.priority ? icons[this.ticket.priority] : '';
    },
    typeIcon() {
      const icons = {
        0: 'question',
        1: 'settings-alt',
        2: 'vet-account',
        3: 'lifebuoy',
      };
      return this.ticket.priority ? icons[this.ticket.type] : '';
    },
    hasNewTicket() {
      return this.ticketList.data.find(ticket => ticket.new);
    },
    hasUnreadMessage() {
      if (
        this.ticket.answers &&
        this.ticket.answers.length > 0 &&
        this.ticket.id !== this.selectedTicket.id
      ) {
        return this.ticket.answers.find(
          answer => answer.read === 0 && answer.user_id !== this.userId
        );
      }
      return false;
    },
  },
  methods: {
    ...mapMutations('support', ['setSelectedTicket', 'setShowTicketModal']),
  },
};
</script>

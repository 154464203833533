var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      staticClass: "bg-opacity-75 hover:bg-beigeLighter",
      class: {
        "bg-yellow-100 hover:bg-yellow-200":
          _vm.hasUnreadMessage || _vm.ticket.new,
        "bg-blue-100 hover:bg-blue-200": _vm.previousTicketId === _vm.ticket.id,
      },
    },
    [
      _c(
        "table-data",
        {
          staticClass: "cursor-pointer",
          nativeOn: {
            click: function ($event) {
              _vm.setShowTicketModal(true), _vm.setSelectedTicket(_vm.ticket)
            },
          },
        },
        [
          _c("div", { staticClass: "flex items-center justify-end" }, [
            _c(
              "div",
              { staticClass: "flex text-xs font-semibold text-white" },
              [
                _vm.hasNewTicket
                  ? _c("div", { staticClass: "mr-2 w-10" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-10 rounded-lg bg-yellow-400 px-2 py-1",
                          class: {
                            invisible: !_vm.ticket.new,
                            hidden: !_vm.hasNewTicket,
                          },
                        },
                        [_vm._v(" New ")]
                      ),
                    ])
                  : _vm._e(),
                _vm.ticket.answers && _vm.ticket.answers.length
                  ? _c("div", { staticClass: "w-10" }, [
                      _c(
                        "div",
                        {
                          staticClass: "w-8 rounded-lg px-2 py-1 text-center",
                          class: {
                            "bg-yellow-400": _vm.hasUnreadMessage,
                            "bg-primary-dark": !_vm.hasUnreadMessage,
                            invisible:
                              !_vm.ticket.answers.length && !_vm.ticket.new,
                            hidden:
                              !_vm.ticket.answers.length && _vm.ticket.new,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.ticket.answers && _vm.ticket.answers.length
                                  ? _vm.ticket.answers.length
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm.ticket.country
              ? _c("img", {
                  staticClass: "ml-3 h-5 w-6",
                  attrs: {
                    src: require("@/assets/svg/flags/" +
                      _vm.ticket.country.localization.toLowerCase() +
                      ".svg"),
                  },
                })
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "table-data",
        {
          staticClass: "cursor-pointer",
          nativeOn: {
            click: function ($event) {
              _vm.setShowTicketModal(true), _vm.setSelectedTicket(_vm.ticket)
            },
          },
        },
        [_c("p", [_vm._v("#" + _vm._s(_vm.ticket.id))])]
      ),
      _vm.isAdmin
        ? _c(
            "table-data",
            {
              staticClass: "cursor-pointer",
              nativeOn: {
                click: function ($event) {
                  _vm.setShowTicketModal(true),
                    _vm.setSelectedTicket(_vm.ticket)
                },
              },
            },
            [
              _c("div", { staticClass: "flex flex-col space-y-1" }, [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.ticket.user.display_name) + " "),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "table-data",
        {
          staticClass: "cursor-pointer",
          nativeOn: {
            click: function ($event) {
              _vm.setShowTicketModal(true), _vm.setSelectedTicket(_vm.ticket)
            },
          },
        },
        [
          _c("div", { staticClass: "flex flex-col space-y-1" }, [
            _c("div", { staticClass: "inline-flex space-x-2" }, [
              _vm.ticket.priority
                ? _c(
                    "div",
                    [
                      _vm.priorityIcon
                        ? _c(
                            "fv-icon",
                            {
                              class: {
                                "text-green-600": _vm.ticket.priority == 3,
                                "text-yellow-600": _vm.ticket.priority == 2,
                                "text-red-600": _vm.ticket.priority == 1,
                                "animate-pulse":
                                  _vm.ticket.priority == 1 &&
                                  !_vm.ticket.status,
                              },
                              attrs: { icon: _vm.priorityIcon, size: "xs" },
                            },
                            [_vm._v(" " + _vm._s(_vm.ticket.priority) + " ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.priorityList.find(function (prio) {
                        return prio.id == _vm.ticket.priority
                      })
                        ? _vm.priorityList.find(function (prio) {
                            return prio.id == _vm.ticket.priority
                          }).shortName
                        : "-"
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "table-data",
        {
          staticClass: "cursor-pointer",
          nativeOn: {
            click: function ($event) {
              _vm.setShowTicketModal(true), _vm.setSelectedTicket(_vm.ticket)
            },
          },
        },
        [
          _c("div", { staticClass: "flex flex-col space-y-1" }, [
            _c("p", { staticClass: "max-w-xs truncate" }, [
              _vm._v(" " + _vm._s(_vm.ticket.message) + " "),
            ]),
          ]),
        ]
      ),
      _c(
        "table-data",
        {
          staticClass: "cursor-pointer",
          nativeOn: {
            click: function ($event) {
              _vm.setShowTicketModal(true), _vm.setSelectedTicket(_vm.ticket)
            },
          },
        },
        [
          _c("div", { staticClass: "flex flex-col space-y-1" }, [
            _c(
              "div",
              { staticClass: "inline-flex items-center space-x-2" },
              [
                _vm.typeIcon
                  ? _c("fv-icon", {
                      staticClass: "text-gray-600",
                      attrs: { icon: _vm.typeIcon },
                    })
                  : _vm._e(),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.types.find(function (type) {
                          return type.id === +_vm.ticket.type
                        })
                          ? _vm.types.find(function (type) {
                              return type.id === +_vm.ticket.type
                            }).name
                          : "-"
                      ) +
                      " "
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "table-data",
        {
          staticClass: "cursor-pointer",
          nativeOn: {
            click: function ($event) {
              _vm.setShowTicketModal(true), _vm.setSelectedTicket(_vm.ticket)
            },
          },
        },
        [
          _c("div", { staticClass: "flex flex-col space-y-1" }, [
            _c(
              "div",
              { staticClass: "inline-flex items-center space-x-2" },
              [
                _c("fv-icon", {
                  staticClass: "text-base",
                  class: _vm.ticket.status ? "text-green-600" : "text-black",
                  attrs: { icon: _vm.ticket.status ? "check-mark" : "warning" },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "table-data",
        {
          staticClass: "cursor-pointer",
          nativeOn: {
            click: function ($event) {
              _vm.setShowTicketModal(true), _vm.setSelectedTicket(_vm.ticket)
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.format(
                    new Date(_vm.ticket.updated_at),
                    "HH:mm yyyy-MM-dd"
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c("table-data", [
        _c("div", { staticClass: "flex justify-end space-x-2" }, [
          _c(
            "button",
            {
              staticClass: "rounded border px-4 hover:opacity-75",
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name:
                      _vm.$route.name === "support-tickets"
                        ? "support-ticket"
                        : "message",
                    params: {
                      id: _vm.ticket.id,
                    },
                  })
                },
              },
            },
            [_c("fv-icon", { attrs: { icon: "chevron-right", size: "md" } })],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
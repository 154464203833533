var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal",
    {
      attrs: { show: _vm.show },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _vm._t("default", function () {
        return [
          _c("spinner-overlay", {
            attrs: { color: "light", loading: _vm.loading },
          }),
          _c(
            "div",
            { staticClass: "flex items-center justify-between border-b pb-4" },
            [
              _c("h2", { staticClass: "text-2xl" }, [
                _vm._v(
                  " Ticket #" +
                    _vm._s(_vm.ticket.id) +
                    " (" +
                    _vm._s(
                      _vm.format(
                        new Date(_vm.ticket.updated_at || null),
                        "yyyy-MM-dd HH:mm"
                      )
                    ) +
                    ") "
                ),
              ]),
              _c(
                "base-button",
                {
                  staticClass: "text-right",
                  attrs: {
                    color: "primary",
                    to: {
                      name:
                        _vm.$route.name === "support-tickets"
                          ? "support-ticket"
                          : "message",
                      params: {
                        id: _vm.ticket.id,
                      },
                    },
                  },
                },
                [_vm._v(" Go to ticket ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _c("SupportTicketForm", {
                staticClass: "mt-6",
                attrs: { ticket: _vm.ticket },
                on: {
                  loading: function ($event) {
                    _vm.loading = $event
                  },
                  updated: function ($event) {
                    return _vm.$emit("updated")
                  },
                },
              }),
              _c("p", { staticClass: "px-4 py-6 pl-0 text-lg" }, [
                _vm._v(" " + _vm._s(_vm.ticket.message) + " "),
              ]),
              _c("SupportTicketConversation", {
                staticClass: "mt-4",
                attrs: { ticket: _vm.ticket },
              }),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <base-modal :show="show" @close="$emit('close')">
    <slot>
      <spinner-overlay color="light" :loading="loading" />
      <div class="flex items-center justify-between border-b pb-4">
        <h2 class="text-2xl">
          Ticket #{{ ticket.id }} ({{
            format(new Date(ticket.updated_at || null), 'yyyy-MM-dd HH:mm')
          }})
        </h2>
        <base-button
          class="text-right"
          color="primary"
          :to="{
            name:
              $route.name === 'support-tickets' ? 'support-ticket' : 'message',
            params: {
              id: ticket.id,
            },
          }"
        >
          Go to ticket
        </base-button>
      </div>

      <div class="w-full">
        <SupportTicketForm
          class="mt-6"
          :ticket="ticket"
          @loading="loading = $event"
          @updated="$emit('updated')"
        />

        <p class="px-4 py-6 pl-0 text-lg">
          {{ ticket.message }}
        </p>
        <SupportTicketConversation class="mt-4" :ticket="ticket" />
      </div>
    </slot>
  </base-modal>
</template>

<script>
import { format } from 'date-fns';
import SupportTicketConversation from '@/components/support/SupportTicketConversation';
import SupportTicketForm from '@/components/support/SupportTicketForm';

export default {
  components: {
    SupportTicketConversation,
    SupportTicketForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    ticket: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      format,
    };
  },
};
</script>
